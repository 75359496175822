import Slider from "@vueform/slider";

export default {
  name: "Premium",
  components: {
    Slider
  },
  data() {
    return {
      slider: {
        min: 1,
        max: 6,
        value: 6
      },
      price: 0, // Will be set on created
      showFromText: true,
      // TODO: replace by backend call:
      priceTable: [
        {
          units: 1,
          price: 19
        },
        {
          units: 2,
          price: 19
        },
        {
          units: 3,
          price: 17
        },
        {
          units: 4,
          price: 17
        },
        {
          units: 5,
          price: 17
        },
        {
          units: 6,
          price: 15
        }
      ]
    };
  },
  methods: {
    sliderValueChanged(value) {
      // Once the slider is changed by a user, we remove the "VANAF" text:
      this.showFromText = false;

      //Recalculate price:
      const defaultPrice = this.priceTable.find(pt => pt.units === value)?.price;
      if (defaultPrice) {
        this.price = defaultPrice;
      }
    }
  },
  created() {
    // set default value from api data:
    const defaultPrice = this.priceTable.find(pt => pt.units === 6)?.price;
    if (defaultPrice) {
      this.price = defaultPrice;
    }
  }
};
