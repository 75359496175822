<template>
	<div class="c-premium__banner">
		<div class="o-row o-row--sm">
			<div class="o-container--lg">
				<div class="c-premium__banner--bg" />
			</div>
		</div>
	</div>
	<div class="c-premium__content">
		<div class="grid">
			<div class="row">
				<h1 class="c-premium__content--title">Premium automaat prijslijst</h1>
				<p class="c-premium__content--description">
					PREMIUM BINNENKORT BESCHIKBAAR <br />Voeg uw automaat nu gratis toe. De premium mogelijkheden zullen
					binnenkort beschikbaar worden. <br />
					Wij houden u hiervan op de hoogte. Alvast bedankt voor uw geduld.
				</p>
			</div>
		</div>
	</div>
	<div class="o-row">
		<div class="o-container">
			<div class="c-premium__options">
				<div class="c-premium__option c-premium__option--free">
					<div class="c-premium__option--head">
						<img class="c-premium__option--icon" src="~@/assets/images/puzzlefree.png" alt="free" />
						<p class="c-premium__option--title">Gratis</p>
						<p class="c-premium__option--subtitle">Voeg gratis uw automaten toe aan het platform.</p>
						<p class="c-premium__option--price">
							<span class="c-premium__option--price-value">€0</span>
							<span>/automaat</span>
						</p>
						<router-link
							class="c-button c-button--secondary"
							:to="{ name: 'Machine-create' }"
							title="Voeg automaat toe"
						>
							Voeg gratis automaten toe
						</router-link>
					</div>
					<hr class="c-hr--dark" />
					<div class="c-premium__option--features">
						<ul>
							<li>Automaat categorie</li>
							<li>Adres</li>
							<li>Basis openingsuren</li>
							<li>Betaalmogelijkheden</li>
						</ul>
					</div>
				</div>
				<div class="c-premium__option c-premium__option--premium">
					<div class="c-premium__option--head">
						<img class="c-premium__option--icon" src="~@/assets/images/puzzlepremium.png" alt="premium" />
						<p class="c-premium__option--title">Premium</p>
						<p class="c-premium__option--subtitle">Licht uw automaat uit door tal van extra functies.</p>
						<div class="c-premium__option--price">
							<span v-if="showFromText" class="c-premium__option--price-start">Vanaf</span>
							<span class="c-premium__option--price-value">€ {{ price }}</span>
							<span class="c-premium__option--price-divider">/</span>
							<div class="c-premium__option--price-period">
								<span>automaat</span>
								<span>per jaar</span>
							</div>
						</div>
						<p class="c-premium__option--subtitle">Hoeveel automaten heeft u?</p>
						<div class="c-premium__option--slider">
							<span>0</span>
							<Slider
								v-model="slider.value"
								class="c-slider"
								:min="slider.min"
								:max="slider.max"
								:tooltips="true"
								@update="sliderValueChanged"
							/>
							<span>6+</span>
						</div>

						<div class="c-premium__option--direct-debit">
							<label class="a-input__checkbox-container">
								<span>Via domiciliering</span>
								<input type="checkbox" />
								<span class="a-input__checkbox-checkmark" />
							</label>
						</div>
					</div>
					<hr class="c-hr--dark" />
					<div class="c-premium__option--features">
						<ul>
							<li>Automaat categorie</li>
							<li>Adres</li>
							<li>Basis openingsuren</li>
							<li>Betaalmogelijkheden</li>
							<li>Persoonlijke naam</li>
							<li>Beschrijving</li>
							<li>Link naar website/sociale media</li>
							<li>Foto's</li>
							<li>Gepersonaliseerde pion op kaart</li>
							<li>Verified badge</li>
						</ul>
						<router-link class="c-button" :to="{ name: 'Premium' }" title="Ga naar Automaten">
							Binnenkort beschikbaar
						</router-link>
						<!-- <router-link class="c-button" :to="{ name: 'MyMachines' }" title="Ga naar Automaten">
							Neem premium
						</router-link> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./Premium.controller.js"></script>
<style lang="scss" src="./Premium.styles.scss"></style>
